import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import { Tab, Segment, Menu, Search, Icon,Image,Header} from 'semantic-ui-react';

import PlacesList from './PlacesList'
import PlacesMap from './PlacesMap'
import Devices from '../Devices';

class PlacesBase extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      places: [],
      devices: null,
      deviceStates : null
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      this.getPlaces()
      // if(this.state.placesMode === "map" && !this.state.devices )
      //   this.getDevices();
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.firebaseInit();
    // if(this.state.placesMode === "map" && !this.state.devices) {
    //   this.getDevices();
    // }
    
  }

  // getDevices = async () =>{
  //   const devices = {}
  //   // console.log("getDevices")
  //   // let deviceIds = []
  //   // for (const place of  this.state.places){
  //   //   deviceIds = deviceIds.concat(place.devices)
  //   // } 
  //   // // console.log(deviceIds);
  //   // for(let i =0 ;i < deviceIds.length; i+=10){
  //   //   const snapshot = await this.props.firebase.devicesByIdList(deviceIds.slice(i,i+10)).get();
  //   //   // const snapshot = await this.props.firebase.devices().get();
  //   //   snapshot.forEach(doc => {
  //   //     let deviceObject = doc.data();
  //   //     if (deviceObject) {
  //   //       devices[doc.id] = {
  //   //         ...deviceObject,
  //   //         key: doc.id,
  //   //       }
  //   //     }
  //   //   });
  //   //   this.setState({
  //   //     devices,
  //   //   });
  //   //   // console.log(Object.keys(devices))
  //   // }
  //   if ( this.state.places &&  this.state.places.length > 0){
  //     const snapshot = await this.props.firebase.devices().get();
  //     snapshot.forEach(doc => {
  //       let deviceObject = doc.data();
  //       if (deviceObject) {
  //         let online = undefined
  //         if (this.state.deviceStates && this.state.deviceStates[doc.id] && this.state.deviceStates[doc.id].online != undefined)
  //           online = this.state.deviceStates[doc.id].online
  //         devices[doc.id] = {
  //           ...deviceObject,
  //           key: doc.id,
  //           online: online
  //         }
  //       }
  //     });
  //     this.setState({
  //       devices,
  //     });
  //     // console.log(devices)
  //   }
  // }

  getPlaces = async () => {
    // get deviceStates
    const deviceStatesSnapshot = await this.props.firebase.deviceStates().once('value')
    const deviceStatesData = deviceStatesSnapshot.val()

    // get devices
    const devices = {}
    const devicesOfPlaces = {}
    const snapshot = await this.props.firebase.devices().get();
    snapshot.forEach(doc => {
      let deviceObject = doc.data();
      if (deviceObject) {
        let online = undefined
        if (deviceStatesData && deviceStatesData[doc.id] && deviceStatesData[doc.id].online != undefined)
          online = deviceStatesData[doc.id].online
        devices[doc.id] = {
          ...deviceObject,
          id: doc.id,
          key: doc.id,
          online: online
        }
        if(deviceObject["places"]){
          deviceObject["places"].forEach(placeId => {
            if (!devicesOfPlaces[placeId])
              devicesOfPlaces[placeId] = [];
            devicesOfPlaces[placeId].push(devices[doc.id]);
          });
        }

      }
    });

    // console.log(deviceStatesData)
    this.setState({ 
      loading: true,
      placesMode: "list" , 
      devices: devices,
      deviceStates: deviceStatesData
    });
    this.props.firebase
      .places(this.props.authUser)
      .onSnapshot(snapshot => {
        const placesList = []
        snapshot.forEach(doc => {
          let placeObject = doc.data();
          // if (placeObject) {
          //   placesList.push({
          //     ...placeObject,
          //     key: doc.id,
          //   });
          // }
          if (placeObject) {
            // console.log(placeObject)
            let onlineStatus = ""
            let actualDeviceCount = 0
            let actualOnlineCount = 0
            // console.log(placeObject.devices)
            if (devicesOfPlaces[doc.id]){
              devicesOfPlaces[doc.id].forEach((device)=>{
                if (!device.id.startsWith("ct-view")){
                  actualDeviceCount++;
                  if(deviceStatesData[device.id] && deviceStatesData[device.id].online)
                  {
                    actualOnlineCount++;
                  }
                }
              })
              onlineStatus = `${actualOnlineCount} / ${actualDeviceCount}`
            }
            placesList.push({
              ...placeObject,
              onlineStatus,
              actualDeviceCount,
              actualOnlineCount,
              key: doc.id,
            });
          }
        });
        if (placesList.length > 0)
          this.setState({
            places: placesList,
            loading: false,
          });
        else this.setState({
            loading: false,
        });
      });
  };

  render() {
    const { places,devices, loading, placesMode } = this.state;
    const panes = {
      nodevices:<Segment placeholder attached="bottom" ><Header icon> <Icon name='info circle' /> ไม่มีข้อมูลที่ท่านสามารถดูได้ <br/> โปรดแจ้งอีเมลล์ของท่าน <br/>กับทีมงานวีไอพีอินเตอร์เทค  </Header></Segment>,
      loading:<Segment placeholder loading attached="bottom" ></Segment>,
      list: <Segment attached="bottom" ><PlacesList places={places} /></Segment>,
      map: <Segment attached="bottom" style={{ padding: 0 }} ><PlacesMap places={places} devices={devices}/></Segment>,
      
    }
    return (
      <div>
        {places && (
          <>
            <Menu attached='top'>
              <Menu.Item
                icon="list"
                name='List'
                active={placesMode === 'list'}
                onClick={() => this.setState({ placesMode: "list" })}
              />
              <Menu.Item
                icon="map"
                name='Map'
                active={placesMode === 'map'}
                onClick={() => this.setState({ placesMode: "map" })}
              />
            </Menu>
            {loading ? panes.loading : (places.length > 0 ? panes[placesMode]: panes.nodevices)}     
          </>
        )}

      </div>
    );
  }
}

class Places extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => <PlacesBase authUser={authUser} firebase={this.props.firebase} />}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Places);
