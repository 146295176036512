import React from 'react';
import {PlaceListItem} from './PlaceItem'

const PlacesList = ({
  places
}) => (
    <div>
        {places.map((place, index) => (
            <PlaceListItem
                key={place.key}
                place={place}
                isLastItem={index == places.length - 1 }
            />
        ))}
   </div>
);

export default PlacesList;
