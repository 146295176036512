import React, { useEffect, useState } from 'react';
import * as ROLES from './../constants/roles';
import Layout from '../components/layout';
import { compose } from 'recompose';
import {
    withAuthorization,
    withEmailVerification,
} from '../components/Session';

import {
    Container, Segment, Search
} from 'semantic-ui-react'
import Places from '../components/Places'
import { navigate } from 'gatsby';

const HomePageBase = (props) => {
    const [authUser, setAuthUser] = useState(null)

    useEffect(() => {
        if(!authUser)
            setAuthUser(JSON.parse(localStorage.getItem('authUser')));
        else {
            // console.log(authUser)
            // if(!authUser.roles[ROLES.ADMIN] )
            //     navigate('/sorry')
        }
        
    }, [authUser])

 
    return (
        <Container>
            {/* {console.log(props)} */}
            <Places/>
        </Container>
    )
}

const condition = authUser => !!authUser;

const HomePage = compose(
    withEmailVerification,
    withAuthorization(condition),
)(HomePageBase);

export default () => (
    <Layout>
        <HomePage />
    </Layout>
);
