import React from 'react'
import { navigate } from "gatsby"
import { PLACE } from '../../constants/routes'
// import { connect } from "react-redux";
// import { setPlace, setActiveDevice } from "../../redux/actions";
import {
    Button,
    Container,
    Icon,
    Item,
    Divider,
    Card,
    Label
} from 'semantic-ui-react'


const PlaceListItemBase = (props) => (
    <Container>
        <Item.Group divided>
            <Item>
                {/* <Item.Image src='/images/wireframe/image.png' /> */}
                <Item.Content>
                    <Item.Header as='a' style={{"display":"flex", "alignItems" :"center"}}>
                        {props.place.name} &nbsp;
                        <Label circular  size={"tiny"} color={
                                props.place.actualOnlineCount == props.place.actualDeviceCount ? 'green' : 
                                props.place.actualOnlineCount == 0 ? 'red' : 'yellow'
                                }>
                            {props.place.onlineStatus}
                        </Label> 
                    </Item.Header>
                    <Item.Description>
                        {props.place.description}
                    </Item.Description>
                    <Item.Extra>
                        <Button onClick={(e) => { /*props.setPlace(props.place); props.setActiveDevice(props.place.devices[0]);*/ navigate(`${PLACE}/${props.place.key}`) }} primary floated='right'>
                            Reports
                        <Icon name='chevron right' />
                        </Button>
                    </Item.Extra>
                </Item.Content>
            </Item>
        </Item.Group>
        {!props.isLastItem && <Divider />}
    </Container>
)

const PlaceMapItemBase = (props) => (
    <Card fluid style={{ boxShadow: "none" }}>
        <Card.Content style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Card.Header >{props.device.name}</Card.Header>
            <Card.Description>
                {props.place.name}  {" ("}  {props.device.key}   {")"}
            </Card.Description>
        </Card.Content>
        <Card.Content extra style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Button onClick={() => { /*props.setPlace(props.place); props.setActiveDevice(props.device);*/ navigate(`${PLACE}/${props.place.key}`) }} primary floated='right'>
                Reports
                <Icon name='chevron right' />
            </Button>
        </Card.Content>
    </Card>
)

export const PlaceListItem = PlaceListItemBase //connect(null, { setPlace, setActiveDevice })(PlaceListItemBase)
export const PlaceMapItem = PlaceMapItemBase //connect(null, { setPlace, setActiveDevice })(PlaceMapItemBase)