import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Icon, Popup } from 'semantic-ui-react'
import { PlaceMapItem} from './PlaceItem'
 
const Marker = ({ active, place, device }) => <>
  <Popup
    trigger={
      <Icon style={{ transform: "translate(-50%,-100%)"}} name='map marker' size="big" fitted color={active? "green" : (active==undefined? "yellow": "red")}/>
    }
    size='small'
    flowing
    hoverable
  >
    <Popup.Content>
      <PlaceMapItem
        key={place.key}
        place={place}
        device={device}
      />
    </Popup.Content>
  </Popup>
   
</>
 
class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 13.0003076,
      lng: 100.9971926
    },
    zoom: 6
  };
 
  render() {
    // console.log(this.props.places)
    const markers = []

    this.props.places.forEach(place => {
      if(place.devices)
      place.devices.forEach(deviceId => {
        // console.log(deviceId)
        if (this.props.devices && deviceId in this.props.devices){
          const deviceDetail = this.props.devices[deviceId]
          const newKey = `${place.key}-${deviceDetail.key}`  
          // console.log(newKey)
          if(deviceDetail.geopoint){
            markers.push(
            <Marker
              key={newKey}
              lat={deviceDetail.geopoint.latitude}
              lng={deviceDetail.geopoint.longitude}
              active={deviceDetail.online}
              device={deviceDetail}
              place={place}
            />
            )
          }
        }
      })
    });
    return (
      // Important! Always set the container height explicitly
      
      <div style={{ height: '85vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCUhmC_Z2XA_9sACFeeTeJA5jOWlAq4OGM"}}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          {markers}
        </GoogleMapReact>
   
      </div>
    );
  }
}
 
export default SimpleMap;